import { render, staticRenderFns } from "./index.vue?vue&type=template&id=66e29b0c&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginFormLogin: require('C:/dev/nuxtProjects/avataprojects/components/login/FormLogin.vue').default})
