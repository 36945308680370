//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("error", ["error"])
  },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    ...mapActions(["login"]),
    postData() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
          routerInfo: "/"
        };

        this.login(payload);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
